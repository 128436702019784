import React from "react";
import NewsTopbar from "components/newsCard/newsTopbar";
import { Col } from "react-bootstrap";
// import ReadNews from "components/newsCard/readNews";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useWindowSize } from "api/commonConstants";
import ReadNewsCardNew from "components/newsCard/ReadNewsCardNew/index";
const NewsImageTextBanner1 = dynamic(
  () =>
    import("components/newsCard/newsImageTextBanner/newsImageTextBanner1.js"),
  { ssr: false }
);

const RestCategory = ({ categoryInfo, newsData }) => {
  console.log("categoryInfo", categoryInfo);
  const router = useRouter();
  const size = useWindowSize();
  return (
    <>
      {categoryInfo?.name === "Entertainment" &&
        router.pathname === "/" &&
        size.width > 600 && (
          <div className="container-row py-2" align="center">
            <p
              style={{
                fontSize: "11px",
                color: "#b7b7b7",
                marginBottom: "0px",
              }}
            >
              Advertisement
            </p>
            <div
              id={"div-gpt-ad-1701945220410-4"}
              style={{
                minWidth: "300px",
                minHeight: "100px",
                display: "block !important",
                alignContent: "center",
                textAlign: "-webkit-center",
              }}
            >
              <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html: `
          {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945220410-4')})`,
                }}
              ></script>
            </div>
          </div>
        )}
      {categoryInfo?.name === "Entertainment" &&
        router.pathname === "/" &&
        size.width <= 600 && (
          <div className="container-row py-2" align="center">
            <p
              style={{
                fontSize: "11px",
                color: "#b7b7b7",
                marginBottom: "0px",
              }}
            >
              Advertisement
            </p>
            <div
              id={"div-gpt-ad-1701946028239-3"}
              style={{
                minWidth: "300px",
                minHeight: "100px",
                display: "block !important",
                alignContent: "center",
                textAlign: "-webkit-center",
              }}
            >
              <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html: `
          {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-3')})`,
                }}
              ></script>
            </div>
          </div>
        )}
      {categoryInfo?.name === "Sports" && router.pathname === "/text" && (
        <div className="container-row py-2" align="center">
          <p
            style={{ fontSize: "11px", color: "#b7b7b7", marginBottom: "0px" }}
          >
            Advertisement
          </p>
          <div
            id={
              size.width > 600
                ? "div-gpt-ad-1701945220410-2"
                : "div-gpt-ad-1701946028239-1"
            }
            style={{
              minWidth: "300px",
              minHeight: size.width > 600 ? "100px" : "260px",
              display: "block !important",
              alignContent: "center",
              textAlign: "-webkit-center",
            }}
          >
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html:
                  size.width > 600
                    ? `
          {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945220410-2')})`
                    : `
          {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-1')})`,
              }}
            ></script>
          </div>
        </div>
      )}
      {categoryInfo?.name === "Lallankhas" &&
        router.pathname !== "/" &&
        size.width < 600 && (
          <div className="container-row py-2" align="center">
            <p
              style={{
                fontSize: "11px",
                color: "#b7b7b7",
                marginBottom: "0px",
              }}
            >
              Advertisement
            </p>
            <div
              id={"div-gpt-ad-1701946028239-2"}
              style={{
                minWidth: "300px",
                minHeight: "260px",
                display: "block !important",
                alignContent: "center",
                textAlign: "-webkit-center",
              }}
            >
              <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html: `
          {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-2')})`,
                }}
              ></script>
            </div>
          </div>
        )}
      {categoryInfo?.name === "Lallankhas" &&
        router.pathname !== "/" &&
        size.width > 600 && (
          <div className="container-row py-2" align="center">
            <p
              style={{
                fontSize: "11px",
                color: "#b7b7b7",
                marginBottom: "0px",
              }}
            >
              Advertisement
            </p>
            <div
              id={"div-gpt-ad-1701945220410-3"}
              style={{
                minWidth: "300px",
                minHeight: "100px",
                display: "block !important",
                alignContent: "center",
                textAlign: "-webkit-center",
              }}
            >
              <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html: `
          {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945220410-3')})`,
                }}
              ></script>
            </div>
          </div>
        )}
      {/* {categoryInfo?.name === "Technology" &&
        router.pathname !== "/" &&
        size.width < 600 && (
          <div className="container-row py-2" align="center">
            <p
              style={{
                fontSize: "11px",
                color: "#b7b7b7",
                marginBottom: "0px",
              }}
            >
              Advertisement
            </p>
            <div
              id={"div-gpt-ad-1701946028239-3"}
              style={{
                minWidth: "300px",
                minHeight: "260px",
                display: "block !important",
                alignContent:"center",textAlign:"-webkit-center"
              }}
            >
              <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html: `
          {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-3')})`,
                }}
              ></script>
            </div>
          </div>
        )} */}
      <div className="outer-containers">
        <div className="content-container">
          <div className="news-card-box">
            <NewsTopbar
              newsEn={categoryInfo?.name || ""}
              newsHn={categoryInfo?.nameHn || ""}
              newsIcon={""}
              newsBgclassName={"bar-bg-1"}
              categoryInfo={categoryInfo}
              // horizontalPosts={categorizedNews.horizontalPosts}
              // restPosts={categorizedNews.restPosts}
            />
          </div>
          <div className="content">
            <div className="left-area-Col">
              <div style={{}}>
                {newsData?.slice(0, 1)?.map((postData) => (
                  <Col key={`${postData?._id}`}>
                    <NewsImageTextBanner1 postData={postData} hTag={3} />
                  </Col>
                ))}
              </div>
              {newsData?.slice(1, 3)?.map((postData, index) => (
                <Col key={`${postData?._id}`} className="featuredCards">
                  <ReadNewsCardNew
                    key={`index_${index}_${postData?._id}`}
                    postData={postData}
                    isBanner={false}
                  />
                </Col>
              ))}
            </div>
            <div className="left-area-Col">
              <div style={{}}>
                {newsData?.slice(3, 4)?.map((postData) => (
                  <Col key={`${postData?._id}`}>
                    <NewsImageTextBanner1 postData={postData} hTag={3} />
                  </Col>
                ))}
              </div>
              {newsData?.slice(4, 6)?.map((postData, index) => (
                <Col key={`${postData?._id}`} className="featuredCards">
                  <ReadNewsCardNew
                    key={`index_${index}_${postData?._id}`}
                    postData={postData}
                    isBanner={false}
                  />
                </Col>
              ))}
            </div>
            <div className="right-area-Col">
              <div style={{}}>
                {newsData?.slice(6, 7)?.map((postData) => (
                  <Col key={`${postData?._id}`}>
                    <NewsImageTextBanner1 postData={postData} hTag={3} />
                  </Col>
                ))}
              </div>
              {newsData?.slice(7, 9)?.map((postData, index) => (
                <Col key={`${postData?._id}`} className="featuredCards">
                  <ReadNewsCardNew
                    key={`index_${index}_${postData?._id}`}
                    postData={postData}
                    isBanner={false}
                  />
                </Col>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RestCategory;
